import React, { Component } from 'react'

import ParticlesBg from 'particles-bg'

export default class Example extends Component {
  render () {
    return (
      <>
        <ParticlesBg color="#5698D0" type="cobweb" bg={true} />
      </>
    )
  }
}