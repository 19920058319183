import logo from './icon.png';
import './App.css';
import Bg from './components/Bg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://outernational.co" target="_blank"><img src={logo} className="App-logo" alt="logo" /></a>
        <p class="mt-2">Please forgive the inconvenience. <br />
        We are currently initializing our brand new site.</p>
        <p
          className="App-link"
        >
          Coming Soon!
        </p>
      </header>
      <Bg />
    </div>
  );
}

export default App;
